import React from 'react';

class SearchRecipe extends React.Component {
	constructor(props) {
		super(props);
		this.searchRef = React.createRef();
	}

	state = {
		query: '',
	}

	componentDidMount() {
		this.searchRef.current.focus();
	}

	onChange = e => {
		this.setState({
			query: e.target.value,
		});
	}

	onSubmit = e => {
		e.preventDefault();

		this.props.onSearch(this.state.query);
	}

	onReset = e => {
		e.preventDefault();

		this.props.onReset();

		this.setState({
			query: '',
		});
	}

	render() {
		return (
			<div id="SearchRecipe" className="mb-5">
				<form onSubmit={this.onSubmit} onReset={this.onReset}>
					<div className="input-group">
						<input
							type="text"
							className="form-control form-control-lg"
							id="query"
							ref={this.searchRef}
							onChange={this.onChange}
							value={this.state.query}
						/>

						<div className="input-group-append">
							<button type="submit" className="btn btn-success btn-lg">Search</button>
							<button type="reset" className="btn btn-warning btn-lg">Reset</button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default SearchRecipe;
