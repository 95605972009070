import React from 'react';
import './App.scss';
import axios from 'axios';
import SearchRecipe from './components/SearchRecipe';
import Meal from './components/Meal';

class App extends React.Component {

	state = {
		errorMessage: false,
		meals: null,
	}

	onSearch = query => {
		axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?s=${query}`)
		.then(response => {
			if (response.data.meals === null) {
				this.setState({
					errorMessage: `Sorry, no recipes found for ${query}.`,
					meals: null,
				});
			} else {
				this.setState({
					errorMessage: false,
					meals: response.data.meals,
				});
			}
		})
		.catch(err => {
			this.setState({
				errorMessage: `Oh, sorry, something went wrong. You sure that's something valid to search for?`,
				meals: null,
			})
		});
	}

	onReset = () => {
		this.setState({
			errorMessage: false,
			meals: null,
		});
	}

	render() {
		return (
			<div id="app">
				<div className="container my-5">
					<h1 className="text-center mb-5">
						<span role="img" aria-label="Food?">🍴😋❔</span>
					</h1>

					<SearchRecipe
						onSearch={this.onSearch}
						onReset={this.onReset}
					/>

					{
						this.state.errorMessage ? (
							<div className="alert alert-warning">
								{this.state.errorMessage}
							</div>
						) : (
							<div id="search-results" className="row">
								{
									this.state.meals ? (
										this.state.meals.map(meal => <Meal meal={meal} />)
									) : ''
								}
							</div>
						)
					}
				</div>
			</div>
		)
	}
}

export default App;
