import React from 'react';

const Meal = props => {
	const { strMeal, strMealThumb, idMeal } = props.meal;

	return (
		<div className="search-result col-lg-3 col-md-4 col-sm-6">
			<div className="card bg-dark text-white mb-4">
				{strMealThumb ? <img src={strMealThumb} class="card-img-top" alt={strMeal} /> : ''}
				<div className="card-body">
					<h5 className="card-title">{strMeal}</h5>

					<a href={`https://www.themealdb.com/meal.php?c=${idMeal}`} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Recipe &raquo;</a>
				</div>
			</div>
		</div>
	)
}

export default Meal;
